.chat__container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*   height: 100vh;*/
}

.chat__wrapper {
    width: 800px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
}

.header__section {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ccc;
}

.prompt {
    font-size: 1.7rem;
    padding: 5px;
    font-weight:bold !important;
    width:75% !important;
    border-radius: 5px !important;
    border: 1px solid #ced4da !important;
    outline: none;
}

.pdf-btn {
    font-size: 1.7rem;
    padding: 5px;
    font-weight:bold;
    width:20%;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
}

.summary__section {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    background-color: #f9f9f9;
}

.question_answer__wrapper__chatbot {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

.search-form {
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}

.form-control {
    padding-top: 20px;
    font-size: 2rem;
    line-height: 2rem;
}



.assistant {
    color: blue;
}

.user {
    color: green;
}


.loading {
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;
    color: #555;
}

.error {
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;
    color: red;
}
.thumbnail{
    border:2px solid #dfdfdf;
    border-radius:50%;
}
.question_answer__wrapper__chatbot .single__question__answer {
    margin-bottom: 5px;
}