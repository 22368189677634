.single-form-s-wrapper{
    max-width: 410px;
    margin: auto;
    text-align: center;
    .head{
        span{
            color: #3F3EED;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
        }
        .title{
            font-weight: 600;
            font-size: 24px;
            line-height: 34px;
        }
    }
    .body{
        input{
            height: 50px;
            border: 1px solid #E2E2FF;
            border-radius: 4px;
            margin: 10px 0;
            &:focus{
                border-color: var(--color-primary);
            }
        }
        .check-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            a{
                color: var(--color-primary) !important;
            }
            label{
                font-weight: 400;
                font-size: 16px;
                @media #{$small-mobile} {
                    font-size: 12px;
                }
            }
        }
        button{
            border-radius: 4px;
            margin-top: 25px;
        }
        p{
            margin-top: 20px;
            margin-bottom: 0;
            display: flex;
            justify-content: center;
            @media #{$large-mobile} {
                flex-direction: column;
            }
            a{
                color: var(--color-primary);
            }
        }
    }
    input[type=checkbox] ~ label::before, input[type=radio] ~ label::before {
        /* content: " "; */
        position: absolute;
        top: 1 px;
        left: 0;
        width: 15px;
        height: 15px;
        background-color: #ffffff;
        border-radius: 2px;
        transition: all 0.3s;
        border-radius: 2px;
        border: 1px solid #E2E2FF;
        display: none;
    }
    
    input[type=checkbox] ~ label, input[type=radio] ~ label {
        position: relative;
        font-size: 12px;
        line-height: 17px;
        color: rgb(46, 46, 46);
        font-weight: 400;
        padding-left: 15px;
        cursor: pointer;
    }
    input[type=checkbox]:checked ~ label::after, input[type=radio]:checked ~ label::after {
        opacity: 1;
    }
    input[type=checkbox] ~ label::after, input[type=radio] ~ label::after {
        content: " ";
        position: absolute;
        top: 16%;
        left: 2px;
        width: 10px;
        height: 6px;
        background-color: transparent;
        border-bottom: 2px solid var(--color-primary);
        border-left: 2px solid var(--color-primary);
        border-radius: 2px;
        transform: rotate(-45deg);
        opacity: 0;
        transition: all 0.3s;
    }
    input[type=checkbox] ~ label::after, input[type=radio] ~ label::after {
        content: " ";
        position: absolute;
        top: 16%;
        left: 2px;
        width: 10px;
        height: 6px;
        background-color: transparent;
        border-bottom: 2px solid #ffffff;
        border-left: 2px solid #ffffff;
        border-radius: 2px;
        transform: rotate(-45deg);
        opacity: 0;
        transition: all 0.3s;
    }
    input[type=checkbox] ~ label, input[type=radio] ~ label {
        position: relative;
        font-size: 12px;
        line-height: 17px;
        color: #222222;
        font-weight: 400;
        padding-left: 15px;
        cursor: pointer;
    }
    .single-form-s-wrapper .body .check-wrapper label {
        font-weight: 400;
        font-size: 16px;
    }
    .sign-in-otherway{
        margin-top: 45px;
        .single{
            display: flex;
            align-items: center;
            padding: 15px 30px;
            text-align: center;
            justify-content: center;
            border: 1px solid #E2E2FF;
            border-radius: 4px;
            margin-top: 20px;
            cursor: pointer;
            @media #{$large-mobile} {
                padding: 15px 15px;
            }
            .icon{
                margin-right: 10px;
            }
            p{
                margin-bottom: 0;
                position: relative;
                margin-bottom: -4px;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: #083A5E;
            }
        }
    }
}

 .single-form-s-wrapper{
    padding: 60px 173px;
    background: #F7F7FF;
    border: 1px solid #E5E4FF;
    max-width: 800px;
    border-radius: 10px;
    @media #{$laptop-device} {
        padding: 25px !important;
    }
    @media #{$smlg-device} {
        padding: 25px !important;
    }
}

.register{
    .main-center-content-m-left.center-content {
        @media #{$smlg-device} {
            min-width: 95%;
            max-width: 95%;
        }
        @media #{$large-mobile} {
            min-width: 99%;
            max-width: 99%;
        }
    }
    .rts-register-area {
        @media #{$smlg-device} {
            margin-left: 0;
        }
    }
}


.register .single-form-s-wrapper.reset{
    @media #{$laptop-device} {
        padding: 25px !important;
    }
    @media #{$md-layout} {
        padding: 50px !important;
    }
    @media #{$sm-layout} {
        padding: 25px !important;
    }
}