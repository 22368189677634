
.gallery-image-generator{
    img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 11px;
    }

  .one {
    grid-column: 1 / 3;
  }
  
  .two {
    grid-column: 3 / 4;
  }
  
  .three {
    grid-column: 1 / 2;
  }
  
  .four {
    grid-column: 2 / 4;
  }
  
  .five {
    grid-column: 1 / 4;
  }
  
  .six {
    grid-column: 1 / 2;
  }
  
  .seven {
    grid-column: 2 / 4;
  }
  
  .eight {
    grid-column: 1 / 3;
  }
  
  .nine {
    grid-column: 3 / 4;
  }
  
  .gallery__images-link {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  

  
  .overlay:hover {
    background-color: transparent;
  }
  
  .gallery__images-small {
    transform: scale(1);
    transition: transform 0.4s;
  }
  
  .overlay:hover + .gallery__images-small {
    transform: scale(1.3);
  }
  .gallery-display-item-wrapper{
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  /* style lightbox */
  .gallery__lightbox {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 95%);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s;
  }
  
  .gallery__lightbox-content {
    width: 60%;
    height: 90%;
    position: relative;
  }
  
  .close,
  .back,
  .next {
    position: absolute;
    text-decoration: none;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .close {
    top: -28px;
    right: -20px;
    background-color: var(--color-primary);
    color: #fff;
    width: 50px;
    height: 50px;
  }
  
  .back,
  .next {
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    background-color: var(--color-primary);
    color: #fff;
  }
  
  .back {
    left: -30px;
  }
  
  .next {
    right: -30px;
  }
  
  .gallery__lightbox-image {
    border: 25px solid #f1f1f1;
    border-radius: 4px;
  }
  
  .gallery__lightbox:target {
    opacity: 1;
    pointer-events: auto;
  }
  
}

