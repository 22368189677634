.dash-board-main-wrapper{
    padding: 40px 40px 0 40px;
    margin-top: 120px;
    padding-top: 0;
    @media #{$large-mobile} {
        padding: 0 15px;
    }
    .left-side-bar{
        background: #fff;
        z-index: 2;
        width: 17%;
        border-right: 1px solid #E5E4FF;
        position: fixed;
        padding-top: 120px;
        left: 0;
        min-height: 450px;
        height: 100%;
        padding-left: 30px;
        top: 0;
        width: 320px;
        @media #{$smlg-device} {
            transform: translateX(-80%);
            @media #{$smlg-device} {
                transform: translateX(-80%);
            }
            @media #{$sm-layout} {
                transform: translateX(-100%);
            }
            .single-menu{
                width: 50px;
                justify-content: flex-end;
                margin-left: auto;
                p{
                    display: none;
                }
            }
            .single-menu-wrapper{
                margin-right: 8px;
            }
            .single-menu-wrapper a.collapse-btn::after{
                display: block;
            }
            .user-wrapper{
                .info{
                    display: none;
                }
                span{
                    display: none;
                }
            }
            .bottom-user{
                max-width: max-content;
                padding: 4px;
                margin-left: auto;
                margin-right: 8px;
                height: 50px;
                width: 50px;            
            }
            .pro-upgrade{
                display: none;
            }
        }
        .bottom-user{
            background: #F7F7FF;
            border: 1px solid #E5E4FF;
            padding: 20px 26px;
            border-radius: 6px;
            margin-right: 30px;
            @media #{$smlg-device} {
                max-width: max-content;
                padding: 4px;
                margin-left: auto;
                margin-right: 8px;
                height: 50px;
                width: 50px;
                margin-top: 50px;
            }
            .user-wrapper{
                display: flex;
                align-items: center;
                gap: 20px;
                position: relative;
                span{
                    position: absolute;
                    right: -7px;
                    top: -7px;
                    padding: 0px 8px;
                    font-weight: 500;
                    color: #fff;
                    border-radius: 5px;
                    background: var(--color-primary);
                }
                .info{
                    a{
                        color: #000000;
                        font-size: 14px;
                    }
                    .title{
                        color: #000000;
                    }
                }
            }
            .title{
                margin-bottom: 0;
            }
            .pro-upgrade{
                button{
                    margin-top: 20px;
                    display: flex;
                    align-items: center;
                    gap: 12px;
                    color: #3F3EED;
                    background: #DBDBFF;
                    transition: 0s;
                }
            }
        }
        &.collapsed{
            transform: translateX(-80%);
            @media #{$smlg-device} {
                transform: translateX(-80%);
            }
            .single-menu{
                width: 50px;
                justify-content: flex-end;
                margin-left: auto;
                p{
                    display: none;
                }
            }
            .single-menu-wrapper{
                margin-right: 8px;
            }
            .single-menu-wrapper a.collapse-btn::after{
                display: block !important;
            }
            .user-wrapper{
                .info{
                    display: none;
                }
                span{
                    display: none;
                }
            }
            .bottom-user{
                max-width: max-content;
                padding: 4px;
                margin-left: auto;
                margin-right: 8px;
                height: 50px;
                width: 50px;    
                top: auto;
                margin-top: auto;
                margin-bottom: 0;
                position: absolute;
                bottom: 20px;
                right: 0;        
            }
            .pro-upgrade{
                display: none;
            }
            .inner{
                overflow-y: visible;
                -ms-overflow-style: none;
                scrollbar-width: none;
                overflow-x: visible;
                @media #{$md-layout} {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    overflow: scroll;
                }
                @media #{$sm-layout} {
                    -ms-overflow-style: none;
                    scrollbar-width: none;
                    overflow: scroll;
                }
            }
            .single-menu-wrapper a.collapse-btn::after{
                content: '';
                height: 7px;
                width: 7px;
                background: var(--color-success);
                border-radius: 50%;
                top: 10px;
                right: 5px;
            }
            .single-menu-wrapper .rts-default-sidebar-list li a span {
                display: none;
            }
            .single-menu-wrapper .rts-default-sidebar-list {
                list-style: none;
            }
            .single-menu-wrapper .rts-default-sidebar-list{
                padding-left: 0;
                li{
                    max-width: 31px;
                    margin-left: auto;
                    a{
                        &[aria-expanded="true"]{
                            &::after{
                                display: none;
                            }
                        }
                    }
                }
            }
            .single-menu-wrapper a.collapse-btn[aria-expanded=true]{
                &::after{
                    display: none !important;
                }
            }
        }
        .inner{
            height: calc(100vh - 300px);
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            @media #{$smlg-device} {
                height: calc(100vh - 288px);
            }
        }
        .single-menu-wrapper{
            margin-right: 30px;
            border-bottom: 1px solid #E5E4FF;
            padding-bottom: 30px;
            margin-bottom: 30px;
            @media #{$smlg-device} {
                margin-right: 8px;
            }
            &:last-child{
                border-bottom: none;
                margin-bottom: 0;
            }
            .single-menu{
                display: flex;
                align-items: center;
                gap: 14px;
                padding: 11px 14px;
                background: #fff;
                border: 1px solid transparent;
                border-radius: 4px;
                cursor: pointer;
                &.active{
                    background: #F7F7FF;
                    border: 1px solid #E5E4FF;
                    p{
                        color: var(--color-primary);
                    }
                }
                p{
                    margin: 0;
                    font-size: 16px;
                    line-height: 20px;
                    color: #001C42;
                    font-weight: 500;
                    position: relative;
                    transition: .3s;
                    margin-bottom: -5px;
                }
                &:hover{
                    background: #F7F7FF;
                    border: 1px solid #E5E4FF;
                    p{
                        color: var(--color-primary);
                    }
                }
            }
            a.collapse-btn{
                position: relative;
                &::after{
                    position: absolute;
                    content: '\f078';
                    right: 10px;
                    font-size: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-family: var(--font-three);
                }
                &[aria-expanded="true"]{
                    background: #F7F7FF;
                    border: 1px solid #E5E4FF;
                    p{
                        color: var(--color-primary);
                    }
                    &::after{
                        content: '\f077';
                    }
                }
            }
            .rts-default-sidebar-list{
                list-style: inherit;
                padding-left: 70px;
                li{
                    a{
                        color: #001C42;
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 4px 0;
                    }
                }
            }
        }
    }
}


.register{
    .dash-board-main-wrapper{
        margin-top: 65px;
    }
}

@media #{$md-layout} {
    .single-menu-wrapper a.collapse-btn::after{
        content: '';
        height: 7px;
        width: 7px;
        background: var(--color-success);
        border-radius: 50%;
        top: 10px;
        right: 5px;
    }
    .single-menu-wrapper .rts-default-sidebar-list li a span {
        display: none;
    }
    .single-menu-wrapper .rts-default-sidebar-list {
        list-style: none;
    }
    .single-menu-wrapper .rts-default-sidebar-list{
        padding-left: 0;
        li{
            max-width: 31px;
            margin-left: auto;
            a{
                &[aria-expanded="true"]{
                    &::after{
                        display: none;
                    }
                }
            }
        }
    }
    .single-menu-wrapper a.collapse-btn[aria-expanded=true]{
        &::after{
            display: none !important;
        }
    }
}


.dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu{
    &::after{
        display: none;
    }
    &::before{
        display: none;
    }
}
.dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu.collapse-btn{
    &::after{
        display: block;
    }
}

.dash-board-main-wrapper .left-side-bar.collapsed .single-menu-wrapper .single-menu{
    &::after{
        display: block;
    }
    &::before{
        display: block;
    }
}

.left-side-open-clouse{
    img{
        transition: all 0.4s ease;
    }
}

.left-side-open-clouse.collapsed{
    background: var(--color-primary);
    img{
        transform: rotate(0.5turn);
        filter: brightness(0) saturate(100%) invert(100%) sepia(97%) saturate(13%) hue-rotate(237deg) brightness(104%) contrast(104%);
    }
}


.main-center-content-m-left{
    margin-left: 305px;
    transition: all 0.4s ease;
    @media #{$smlg-device} {
        margin-left: 60px;
    }
    @media #{$sm-layout} {
        margin-left: 0;
    }
    &.collapsed{
        margin-left: 60px;
        transform: translateX(0);
        @media #{$smlg-device} {
            transform: translateX(0);
        }
    }
    .banner-badge{
        border-radius: 7px;
        padding: 40px;
        background-image: url(../images/bg/01.jpg);
        position: relative;
        @media #{$large-mobile} {
            padding: 25px;
        }
        .inner{
            max-width: 50%;
            @media #{$sm-layout} {
                max-width: 100%;
            }
            .title{
                font-size: 36px;
                color: #fff;
                margin-bottom: 10px;
                @media #{$large-mobile} {
                    font-size: 32px;
                    line-height: 1.3;
                }
                @media #{$small-mobile} {
                    font-size: 24px;
                    line-height: 1.3;
                }
            }
            p.dsic{
                font-size: 16px;
                color: #fff;
            }
            .inner-right-iamge{
                position: absolute;
                bottom: -4px;
                right: 130px;
                @media #{$laptop-device} {
                    right: 50px;
                }
                @media #{$smlg-device} {
                    right: 50px;
                }
                @media #{$md-layout} {
                    right: 20px;
                }
                @media #{$sm-layout} {
                    display: none;
                }
            }
            .rts-btn{
                background: #fff;
                color: var(--color-primary);
                &:hover{
                    padding: 10px 31px;
                    background: rgba(217, 217, 217, 0.06);
                    border: 1px solid rgba(151, 150, 233, 0.1);
                    backdrop-filter: blur(25px);
                    border-radius: 4px;
                    letter-spacing: 0.03em;
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
}


@media (min-width: 1600px) {
    .col-show-5 {
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 20%;
    }
}

.container-custom{
    max-width: 1072px;
    margin: auto;
    @media #{$smlg-device} {
        max-width: 95%;
        margin: auto;
    }
}

.user-profile-area {
    margin-left: 320px;
    @media #{$smlg-device} {
        margin-left: 0;
    }
}


.user-profile .dash-board-main-wrapper {
    padding: 40px 40px 0 40px;
    margin-top: 90px;
    padding-top: 0;
    @media #{$small-mobile} {
        padding: 40px 0 0 0px;
    }
}

.user-profile-main-wrapper{
    padding: 30px;
    border-radius: 4px;
    border: 1px solid #DDD8F9;
    @media #{$small-mobile} {
        padding: 20px;
    }
    .thumbnaiul{
        position: relative;
        .profile-image{
            left: 30px;
            bottom: -30px;
            position: absolute;
            border-radius: 50%;
            padding: 10px;
            background: #fff;
            overflow: hidden;
            @media #{$small-mobile} {
                left: 30px;
                bottom: -30px;
                right: 0;
                position: absolute;
                border-radius: 50%;
                padding: 10px;
                background: #fff;
                overflow: hidden;
                left: auto;
            }
            img{
                transform: scale(1.01);
            }
            &::after{
                position: absolute;
                content: '';
                left: 0;
                bottom: 0;
                right: 0;
                height: 60px;
                background: rgba(0, 0, 0, 0.4);
                border-radius: 0;
                border-end-end-radius: 58px;
                border-end-start-radius: 58px;
                width: calc(100% - 20px);
                left: 10px;
                right: 10px;
                bottom: 10px;
            }
        }
        button{
            position: absolute;
            right: 30px;
            bottom: 30px;
            max-width: max-content;
            @media #{$large-mobile} {
                bottom: -80px;
                max-width: max-content;
                font-size: 14px;
                left: 0;
                right: auto;
            }
        }
    }
}


.user-profile-content-details{
    @media #{$large-mobile} {
        margin-top: 111px;
    }
    form{
        margin-top: 80px;
        .half-input-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 30px;
            margin-bottom: 30px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
            }
            label{
                color: #110C2D;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            input{
                flex-basis: 100%;
                height: 46px;
                border-radius: 4px;
                border: 1px solid #DDD8F9;
            }
            .single{
                flex-basis: 78%;
                @media #{$large-mobile} {
                    width: 100%;
                }
            }
        }
        label{
            color: #110C2D;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        textarea{
            border: 1px solid #DDD8F9;
            height: 88px;
            border-radius: 4px;
            font-size: 14px;
            text-align: left;
        }
        button{
            max-width: max-content;
        }
    }
}


.user-profile-main-wrapper .thumbnaiul img {
    @media #{$large-mobile} {
        height: 120px;
    }
}