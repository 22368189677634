
// community details

.blog-details-right-wrapper{
    margin-left: 305px;
    @media #{$smlg-device} {
        margin-left: 50px;
    }
    @media #{$sm-layout} {
        margin-left: 0;
    }
}

.top-blog-details-start .title-area .title {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 44px;
    line-height: 54px;
    color: #083A5E;
    line-height: 1.3;
    @media #{$sm-layout} {
        font-size: 28px;
        line-height: 1.2;
    }
    @media #{$large-mobile} {
        font-size: 22px;
        line-height: 1.2;
    }
}
.authore-bd-area{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 70px;
    @media #{$md-layout} {
        justify-content: flex-start;
    }
    @media #{$sm-layout} {
        gap: 30px;
        flex-direction: column;
        align-items: flex-start;
    }
    .sub-area{
        p{
            margin-bottom: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 34px;
            color: #083A5E;
            margin-bottom: -5px;
        }
        span.deg{
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #497696;
            margin-bottom: 0;
        }
    }
    .main{
        display: flex;
        align-items: center;
        gap: 15px;
        .info{
            .deg{
                font-weight: 600;
                font-size: 16px;
                color: #083A5E;
                display: block;
                margin-bottom: -5px;
            }
            .name{
                font-weight: 600;
                font-size: 14px;
                color: #497696;
            }
        }
    }
}


.container-bd{
    max-width: 990px;
    margin: auto;
}
.para-area-wrapper{
    img{
        border-radius: 10px;
    }
    p{
        margin-bottom: 30px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}


.quote-area-bd{
    padding: 36px 15px;
    width: 100%;
    background: rgba(237, 237, 255, 0.5);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    @media #{$large-mobile} {
        padding: 15px; 
    }
    .inner{
        display: flex;
        align-items: flex-start;
        gap: 26px;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
        }
        .title{
            color: var(--color-primary);
            margin-bottom: 0;
            font-weight: 600;
            @media #{$large-mobile} {
                font-size: 18px;
                font-weight: 600;
            }
            span{
                color: #083A5E;
                font-size: 20px;
            }
        }
    }
}

.main-image-big{
    img{
        border-radius: 10px;
    }
}