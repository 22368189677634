// single community feed style
.single-cummunity-feed{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 5px;
    .thumbnail{
        display: block;
        border-radius: 13px;
        overflow: hidden;
        img{
            width: 100%;
            border-radius: 13px;
            transition: .5s;
        }
    }
    .inner-content-area{
        position: absolute;
        left: 0;
        width: 85%;
        left: 50%;
        transform: translateX(-50%);
        background: #FFFFFF;
        padding: 14px;
        border-radius: 5px;
        transition: all 0.4s ease;
        bottom: -100%;
        .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            .left-area{
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 15px;
                line-height: 16px;
            }
            .love-reaction{
                span{
                    font-size: 14px;
                }
            }
        }
        p.disc{
            font-size: 14px;
            line-height: 1.3;
        }
    }
    .thumbnail{
        img{
            width: 100%;
        }
    }
    &:hover{
        .inner-content-area{
            bottom: 15px;
        }
        .thumbnail{
            img{
                transform: scale(1.2);
            }
        }
    }
}

.nav-search-between{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    @media #{$md-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
    }
    .nav-pills{
        margin: 0 !important;
        li{
            margin: 0 !important;
        }
    }
    .left-area{
        form{
            position: relative;
            button{
                position: absolute;
                background: var(--color-primary);
                max-width: max-content;
                padding: 5px 12px;
                border-radius: 3px;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                color: #FFFFFF;
                font-size: 14px;
            }
            input{
                height: 44px;
                border: 1px solid #3F3EED;
                width: 323px;
                border-radius: 4px;
                @media #{$large-mobile} {
                        width: 291px;
                }
            }
        }
    }
}


.title-conter-area.dashboard{
    text-align: center;
}






