[data-theme="dark"] {
    .rts-dark-light {
        .in-light {
            display: none;
        }

        .in-dark {
            display: block;
        }
    }
}

[data-theme="light"] {
    .rts-dark-light {
        .in-light {
            display: block;
        }

        .in-dark {
            display: none;
        }
    }
}

[data-theme="dark"] {

    // body bg
    body,
    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu {
        background: #090E18;
    }

    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu p,
    .dash-board-main-wrapper .left-side-bar .bottom-user .user-wrapper .info .title,
    .dash-board-main-wrapper .left-side-bar .bottom-user .user-wrapper .info a,
    .single_action__haeader.notification .notification_main_wrapper .notification__content .notification__items .single__items .single-link .main-content .name-user,
    .single_action__haeader.user_avatar__information .user_information_main_wrapper .user_body_content .items .single_items .hader_popup_link,
    .single_action__haeader.user_avatar__information .user_information_main_wrapper .user_header .user_naim-information .title {
        color: #fff !important;
    }
    .generated_image__wraper .bottom-image p,
    .quote-area-bd .inner .title span,
    .single-form-s-wrapper .body input,
    .single-form-s-wrapper .body p {
        color: #fff !important;
    }
    .single-cummunity-feed .inner-content-area {
        background: #090e18;
        p{
            color: #fff;
        }
        span{
            color: #fff;
        }
    }

    .chat-history-area-start .single-history:hover {
        background: #121b32;
    }
    .top-blog-details-start .title-area .title,
    .authore-bd-area .main .info .deg ,
    .authore-bd-area .sub-area p,
    .para-area-wrapper p,
    .para-area-wrapper .title {
        color: #f8f9fa;
    }
    .modal-header .btn-close{
        filter: brightness(0) saturate(100%) invert(100%) sepia(96%) saturate(15%) hue-rotate(212deg) brightness(104%) contrast(104%);
    }
    .header-area-one,
    .search__generator .searchoptopn-area input,
    .modal-content,
    .quote-area-bd   {
        background: #151A24;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    .single_action__haeader.search-action .search-opoup,
    .single_action__haeader.notification .notification_main_wrapper {
        background: #151A24;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .single_action__haeader.notification .notification_main_wrapper .title,
    .single_action__haeader.user_avatar__information .user_information_main_wrapper {
        background: #121B32;
        color: #fff;
    }

    .single_action__haeader.user_avatar__information .user_information_main_wrapper .user_header {
        background: #192441;
    }

    .single_action__haeader.notification .notification_main_wrapper::before {
        border-color: #121B32;
    }

    // border
    .search__generator .searchoptopn-area input {
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    // border colort
    .dash-board-main-wrapper .left-side-bar,
    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper,
    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu.active,
    .header-area-one .left-logo-area .left-side-open-clouse,
    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper a.collapse-btn[aria-expanded=true] {
        border-color: rgba(229, 228, 255, 0.05);
    }

    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu.active,
    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper a.collapse-btn[aria-expanded=true] {
        background: rgba(247, 247, 255, 0.05);
    }

    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu {
        &:hover {
            border-color: rgba(229, 228, 255, 0.05) !important;
            background: rgba(247, 247, 255, 0.05) !important;
        }
    }

    .action-interactive-area__header .single_action__haeader {
        background:
            rgba(247, 247, 255, 0.05);
        border: 1px solid rgba(229, 228, 255, 0.05);

        svg {
            path {
                fill: #fff;
            }
        }
    }

    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .single-menu {
        .icon {
            img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(323deg) brightness(101%) contrast(103%);
            }
        }
    }

    .left-side-open-clouse img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(323deg) brightness(101%) contrast(103%);
    }

    .dash-board-main-wrapper .left-side-bar .bottom-user,
    .question_answer__wrapper__chatbot .single__question__answer .question_user,
    .pricing-button-one,
    .main-center-content-m-left.center-content .search-form input,
    .share-reaction-area,
    .accordion-area-one .accordion .accordion-item,
    .image-generatore-right-sidebar-content .single-diamention .nice-select-wrap .drop,
    .image-generatore-right-sidebar-content .single-diamention .nice-select-wrap .drop,
    .image-generatore-right-sidebar-content .nice-select-wrap,
    .image-generatore-right-sidebar-content .number-image-wrapper .single-number,
    .single-form-s-wrapper {
        background: #121B32;
        border-color: #e5e4ff1a;
    }

    .audio-main-generator-start textarea,
    .audio-main-wrapper,
    .single-pricing-single-two .head .date-use,
    .generated_image__wraper,
    .right-side-bar-new-chat-option,
    .image-generatore-right-sidebar-content .single-diamention.border-top,
    .border-top,
    .single-form-s-wrapper .body input,
    .single-form-s-wrapper .sign-in-otherway .single {
        border-color: #e5e4ff1a !important;
    }

    .dash-board-main-wrapper .left-side-bar .bottom-user .pro-upgrade button,
    .accordion-area-one .accordion .accordion-item button {
        background-color: rgba(219, 219, 255, 0.05);
        color: #fff;
    }
    .color-white-title-home{
        color: #fff;
    }
    .header-area-one .logo img {
        min-width: max-content;
        filter: brightness(1);
    }
    .copyright-area-bottom p {
        color: #fff;
    }
    .single_action__haeader.user_avatar__information .user_information_main_wrapper::before {
        border-bottom: 10px solid #121b32;
    }
    .user-profile-inner .title,
    .user-profile-content-details form .half-input-wrapper label,
    .user-profile-content-details form .half-input-wrapper input,
    .user-profile-content-details form  textarea,
    .user-profile-content-details form label {
        color: #fff;
    }
    .single_action__haeader.user_avatar__information .user_information_main_wrapper .user_header .user_naim-information .desig,
    .single_action__haeader.notification .notification_main_wrapper .notification__content .notification__items .single__items .single-link .main-content .disc,
    .single_action__haeader.user_avatar__information .user_information_main_wrapper.language-area .select-language-area li a {
        color: #e3e3e3;
    }

    .user-profile-main-wrapper,
    .user-profile-content-details form .half-input-wrapper input,
    .user-profile-content-details form textarea {
        border: 1px solid #21262f;
    }
    .user-profile-main-wrapper .thumbnaiul .profile-image{
        background: #21262f;
    }

    .dash-board-main-wrapper .left-side-bar .single-menu-wrapper .rts-default-sidebar-list li a,
    .question_answer__wrapper__chatbot .single__question__answer .question_user .left_user_info .question__user,
    .question_answer__wrapper__chatbot .single__question__answer .answer__area .answer_main__wrapper p.disc,
    .question_answer__wrapper__chatbot .single__question__answer .answer__area .answer_main__wrapper .common__title,
    .question_answer__wrapper__chatbot .single__question__answer .answer__area .answer_main__wrapper .order_list_answer ol li p,
    .audio-main-generator-start textarea,
    .audio-main-generator-start .ask-for-audio i.fa-pen-to-square,
    .nav-search-between .title,
    .pricing-plane-area .title-conter-area .title,
    .single-pricing-single-two .body .check-wrapper .check-area p,
    .single-pricing-single-two .body p.para,
    .single-pricing-single-two .head .date-use .title,
    .single-pricing-single-two .head .date-use span,
    .single-pricing-single-two .body .check-wrapper .check-area i,
    .title-conter-area.dashboard .title,
    .title-conter-area.dashboard p.disc,
    .single-form-s-wrapper .body .check-wrapper label,
    .single-form-s-wrapper .sign-in-otherway .single p {
        color: #e2e2e2;
    }

    .audio-main-wrapper .audio-player .timeline,
    .audio-main-wrapper .audio-players .timeline,
    .audio-main-wrapper .audio-playerer .timeline {
        background: #121b32;
    }

    .dash-board-main-wrapper .left-side-bar {
        background: transparent;
    }

    .image-generatore-right-sidebar-content .single-diamention .nice-select-wrap .drop::after {
        color: #ffffff;
    }

    .copyright-area-bottom {
        border-top: 1px solid #151a24;
    }

    .single-pricing-single-two {
        box-shadow: 1px 2px 10px 4px rgb(48 48 48 / 20%);
    }

    .nav-search-between .left-area form input {
        color: #909090;
    }

    .single-pricing-single-two.active,
    .generated_image__wraper,
    .image-generatore-right-sidebar-content .single-diamention .nice-select-wrap .drop {
        background: #1c1c39;
    }

    .pricing-plane-area .title-conter-area .pre-title-bg,
    .single-pricing-single-two .body .check-wrapper .check-area p,
    .main-center-content-m-left.center-content .search-form input,
    .chat-history-area-start .single-history p,
    .accordion-area-one .accordion .accordion-item .accordion-body {
        color: #c5c5c5;
    }

    .main-center-content-m-left.center-content .search-form {
        background: #090e18;
    }

    .chat-history-area-start .title,
    .image-generatore-right-sidebar-content .single-diamention .title {
        color: #fff;
        font-weight: 500;
    }

    .generated_image__wraper .bottom-image .share-reaction-area li i,
    .image-generatore-right-sidebar-content .single-diamention .nice-select-wrap .drop,
    .range-main-wrapper p,
    .image-diamention.single-diamention .disc,
    .image-generatore-right-sidebar-content .number-image-wrapper .single-number,
    .image-generatore-right-sidebar-content .nice-select-wrap ul a,
    .single-form-s-wrapper .head .title {
        color: #fff;
        opacity: 1;
    }

    .generated_image__wraper .bottom-image .botton-content-between .left-area img {
        filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(672%) hue-rotate(175deg) brightness(106%) contrast(102%);
    }

    .image-generatore-right-sidebar-content .nice-select-wrap ul a::after {
        background: #fffefe0a;
    }
}