.chat__container {
    display: flex;
    justify-content: center;
    align-items: center;
    /*   height: 100vh;*/
}

.chat__wrapper {
    width: 800px;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #f9f9f9;
    border-radius: 8px;
    border-color: grey;
    padding:10px;
}

.header__section {
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    background-color: #f9f9f9;
    border-bottom: 1px solid #ccc;
}

.prompt {
    font-size: 1.7rem;
    padding: 5px;
    font-weight:bold !important;
    width:75% !important;
    border-radius: 5px !important;
    border: 1px solid #ced4da !important;
    outline: none;
}
button{
    max-width: 200px;
}

.pdf-btn {
    font-size: 1.7rem;
    padding: 5px;
    font-weight:bold;
    width:20%;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
}

.summary__section {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    background-color: #f9f9f9;
}

.question_answer__wrapper__chatbot {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

.search-form {
    padding: 10px;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 5px;
}

.answer_main__wrapper{
    background:#DCDCDC;
    padding: 10px;
    marginTop: 0px;
    borderRadius:10px;
    width: 100%;
    cursor: pointer;
    border-radius: 5px;
}
.form-control {
    color:grey !important;
    height:35px !important;
    border:1px solid grey !important;
}



.assistant {
    color: blue;
}

.user {
    color: green;
}

.loading {
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;
    color: #555;
}

.error {
    text-align: center;
    padding: 20px;
    font-size: 1.5rem;
    color: red;
}
.form-group.text-group{
    margin-bottom:10px !important;
}
#textarea{
    resize:vertical;
    border-radius: 10px;
    background: white;
    width: 100%;
    min-height: 30px;
    border:1px solid grey;
    overflow:hidden;

}
.editable_text{
    border-radius: 10px;
    background: white;
    width: 100%;
    min-height: 30px;
    border:1px solid grey;
    overflow:hidden;

}
.question_user{
    background-color: #e9e9e9 !important;
}
.form-group{
    margin-bottom: 0px !important;
}
.thumbnail{
    border:2px solid #dfdfdf;
    border-radius:50%;
}
.question_answer__wrapper__chatbot {
    margin-bottom: 5px;

}
.single__question__answer {
    margin-bottom: 5px;
}