/*=================Scss Indexing=============
1.variables
2.typography
3.spacing
4.reset
5.forms
6.mixins
7.shortcode
8.animations
9.text-animation
10.sal
11.header
12.mobile-menu
13.button
14.nav
15.banner
16.swiper
17.funfacts
18.cta
19.about
20.common
21.service
22.projects
23.working-process
24.blog
25.blog-details
26.footer
27.search-input
28./side-bar
29.team
30.testimonials
31.faq
32.pricing
33.date-picker
34.time-picker
35.appoinment
36.awesome-feedback
37.contact
38.pre-loader.scss
39.back-to-top


==============================================  */

/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';

/* elements */


@import'elements/header';
@import'elements/common';
@import'elements/buttons';
@import'elements/home';
@import'elements/search-generator';
@import'elements/community';
@import'elements/pricing';
@import'elements/faq';
@import'elements/chatbot';
@import'elements/registration';
@import'elements/new-chat-sidebar';
@import'elements/light-box';
@import'elements/nice-select';
@import'elements/audio';
@import'elements/tooltip';
@import'elements/footer';
@import'elements/modal';
@import'elements/community-details';
@import'elements/dark-version';




