
// footer style start

.copyright-area-bottom{
    border-top: 1px solid #E5E4FF;
    margin-top: 40px;
    padding-top: 25px;
    padding-bottom: 25px;
    p{
        margin: 0;
        text-align: center;
        margin-bottom: 0;
        a{
            color: var(--color-primary);
        }
    }
}