// chat bot style

.main-center-content-m-left.center-content{
    min-width: 64%;
    max-width: 64%;
    &.close-right-sidebar{
        min-width: 83%;
        max-width: 83%;
    }
    .search-form{
        position: relative;
        background: #ffffff;
        height: 117px;
        position: fixed;
        width: 100%;
        bottom: 0;
        max-width: 63%;
        input{
            height: 56px;
            width: 100%;
            position: fixed;
            bottom: 20px;
            border: 1px solid var(--color-primary);
            max-width: 1230px;
            background: #F7F7FF;
            border-radius: 4px;
        }
        button{
            position: absolute;
            right: 12px;
            top: 46%;
            max-width: max-content;
            height: 30px;
            min-width: 30px;
            background: #E1E1FF;
            border-radius: 2px;
            i{
                color: var(--color-primary);
            }
        }
    }
}
.main-center-content-m-left.center-content.collapsed.close-right-sidebar{
    max-width: 95%;
    min-width: 95%;
}

body.chatbot{
    &::-webkit-scrollbar {
        display: none !important;
    }
}
.main-center-content-m-left.center-content.collapsed{
    min-width: calc(65% + 14%);
    max-width: calc(65% + 14%);
    .search-form{
        max-width: 80%;
        input{
            max-width: calc(65% + 15%);
        }
    }
}
.question_answer__wrapper__chatbot{
    .single__question__answer{
        margin-bottom: 40px;
        &:last-child{
            margin-bottom: 117px;
        }
        .question_user{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 20px;
            background: #F7F7FF;
            border: 1px solid #E5E4FF;
            border-radius: 4px;
            .left_user_info{
                display: flex;
                align-items: center;
                gap: 15px;
                .question__user{
                    color: #242424;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
        .answer__area{
            padding: 10px;
            display: flex;
            align-items: flex-start;
            gap: 20px;
            margin-top: 14px;
            @media #{$large-mobile} {
                flex-direction: column;
            }
            .thumbnail{
                max-width: max-content;
                min-width: max-content;
                img{
                    min-width: max-content;
                }
            }
            .answer_main__wrapper{
                margin-top: 8px;
                .common__title{
                    color: #242424;
                    font-size: 20px;
                    font-weight: 500;
                }
                p.disc{
                    font-size: 16px;
                    color: #242424;
                    line-height: 26px;
                    margin-bottom: 0px;
                }
                .order_list_answer{
                    ol{
                        li{
                            p{
                                font-size: 16px;
                                line-height: 26px;
                                font-weight: 400;
                                color: #242424;
                            }
                        }
                    }
                }
            }
        }
    }
}
.dash-board-main-wrapper .left-side-bar .single-menu-wrapper .rts-default-sidebar-list li a.active{
    color: var(--color-primary) !important;
}
.share-reaction-area{
    background: #F7F7FF;
    max-width: max-content;
    border-radius: 4px;
    border: 1px solid #E5E4FF;
    padding: 10px 20px;
    margin-left: 65px;
    margin-top: 25px;
    @media #{$sm-layout} {
        margin-left: 0;
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 21px;
        li{
            margin: 0;
            padding: 0;
            a{
                display: block;
                // height: ;
                i{
                    font-size: 20px;
                    color: var(--color-primary);
                }
            }
        }
    }
}

.rts-register-area {
    margin-left: 305px;
}

.chatbot{
    .main-center-content-m-left.center-content {
        min-width: auto;
        max-width: calc(100% - 624px);
        @media #{$smlg-device} {
            max-width: 95%;
        }
    }
}


.chatbot{
    .search-sticky{
        .search-form{
            input{
                transition: 0s;
            }   
            &.active{
                bottom: 77px;
                input{
                    height: 55px;
                    bottom: 95px;
                    transition: 0s !important;
                }   
            }
        }
    }
}


.community-feed{
    .loadmore-btn{
        max-width: max-content;
    }
}